import '../css/login.css';
import { render } from './sites/login';

export function main() {
  const target = document.getElementById('app');
  if (target) render(target);
}

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept();
}

main();
