import { Link } from 'react-router-dom';

export default function SignupLinkSection() {
  return (
    <div className="w-full text-center">
      <Link to="/signup" className="text-purple-200 inline-block p-2">
        Crear una <u>nueva cuenta</u>
      </Link>
    </div>
  );
}
