export default function SiteLayout({ children }) {
  return (
    <div
      className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-purple-700"
      style={{ maxHeight: '100svh' }}
    >
      {children}
    </div>
  );
}
