import { useNavigate } from 'react-router-dom';
import { postAPI } from '../../lib/ajax';
import { showErrorMessage } from '../../lib/notifications';
import Card from '../Card';
import SignupForm from './SignupForm';

export default function SignupCard() {
  const navigate = useNavigate();

  const createAccount = async (data) => {
    try {
      await postAPI('/signup', data);
      navigate('/signup/success');
    } catch (error) {
      showErrorMessage(
        'Se produjo un error al crear su cuenta, revise sus datos e intente nuevamente.',
      );
      throw error;
    }
  };

  return (
    <Card className="p-12 space-y-8">
      <div className="space-y-8">
        <h1 className="text-center mb-8 text-3xl font-bold">Crear una nueva cuenta</h1>
        <div className="space-y-8">
          <SignupForm onSubmit={createAccount} />
        </div>
      </div>
    </Card>
  );
}
