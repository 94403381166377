import { AnimatePresence } from 'framer-motion';
import { createRoot } from 'react-dom/client';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import InstalinkPage from './InstalinkPage';
import LoginPage from './LoginPage';
import SignupPage from './SignupPage';
import SignupSuccessPage from './SignupSuccessPage';
import SiteLayout from './SiteLayout';

/**
 * Render the login site
 *
 * @param target Container element for the rendered component
 */
export function render(target: HTMLElement) {
  const root = createRoot(target);
  root.render(
    <SiteLayout>
      <Router>
        <AnimatePresence>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/login/instalink" element={<InstalinkPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/signup/success" element={<SignupSuccessPage />} />
          </Routes>
        </AnimatePresence>
      </Router>
    </SiteLayout>,
  );
}
