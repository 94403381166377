/**
 * Divider with a text in the middle of it.
 *
 * @example <Divider label="Or" />
 */
export default function Divider({ label, centered = true }) {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-200" />
      </div>
      {centered ? (
        <div className="relative flex justify-center">
          <span className="px-2 bg-white text-sm text-gray-500"> {label} </span>
        </div>
      ) : (
        <div className="relative flex justify-start">
          <span className="pr-2 bg-white text-sm text-gray-500"> {label} </span>
        </div>
      )}
    </div>
  );
}
