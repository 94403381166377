import { without } from 'ramda';

interface IRemoteErrorMessage {
  loc: string[];
  msg: string;
  type: string;
}

export default class ValidationError extends Error {
  name = 'ValidationError';
  formikErrors: Record<string, any>;

  constructor(detail: IRemoteErrorMessage[]) {
    super();

    // Parse response for formik
    this.formikErrors = detail.reduce((acc, error) => {
      const field = without(['body'], error.loc)[0];
      acc[field] = error.msg;
      return acc;
    }, {});
  }
}
