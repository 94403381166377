import qs from 'qs';
import { isEmpty, reject } from 'ramda';
import { useSearchParams } from 'react-router-dom';

export type ISearchParams = qs.ParsedQs;
type DefaultSearchParams = Record<string, string | string[]>;

export function useSearchParamsObj(
  defaults: DefaultSearchParams = {},
): [qs.ParsedQs, (value: ISearchParams) => void] {
  const [searchParamsIter, setSearchParamsIter] = useSearchParams(defaults);
  // @ts-ignore - qs.parse can take an object of type DefaultSearchParams
  const searchParams = qs.parse(searchParamsIter.toString() || defaults);

  const setSearchParams = async (data: ISearchParams) => {
    const params = reject(isEmpty, { ...searchParams, ...data });
    setSearchParamsIter(stringifySearchParams(params));
  };

  return [searchParams, setSearchParams];
}

export function stringifySearchParams(params: Record<string, unknown>): string {
  return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true });
}
