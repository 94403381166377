import axios from 'axios';
import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { object, string } from 'yup';

const validationSchema = object().shape({
  username: string().trim().required('Este campo es obligatorio'),
  password: string()
    .min(8, 'Introduzca al menos 8 caracteres')
    .required('Este campo es obligatorio'),
});

/**
 * Form to login with an email and password. Also includes a
 * "cannot login" button, on clicking redirects to the instalink page.
 *
 * @example <PasswordLoginForm />
 */
export default function PasswordLoginForm() {
  const intitialState = { username: '', password: '' };

  const handleSubmit = async (data, { setErrors }) => {
    const parsedData = validationSchema.cast(data);

    try {
      const response = await axios.post('/auth/password', parsedData, { maxRedirects: 0 });
      window.location.href = response.request.responseURL;
    } catch (error) {
      const { response } = error;
      const { status } = response || {};

      if (status === 401) {
        setErrors({ password: 'La contraseña es incorrecta' });
      } else throw error;
    }
  };

  return (
    <Formik
      initialValues={intitialState}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form className="space-y-4">
          <div className="relative">
            <Field
              id="username"
              type="text"
              name="username"
              placeholder="Nombre de usuario"
              className={classNames('form-input rounded-md', {
                invalid: !!errors.username && touched.username,
              })}
            />
            <label htmlFor="username">Nombre de usuario</label>
            <ErrorMessage name="username" component="div" className="form-input-error-message" />
          </div>
          <div className="relative">
            <Field
              id="password"
              type="password"
              name="password"
              placeholder="Contraseña"
              className={classNames('form-input rounded-md', {
                invalid: !!errors.password && touched.password,
              })}
            />
            <label htmlFor="password">Contraseña</label>
            <ErrorMessage name="password" component="div" className="form-input-error-message" />
          </div>
          <div className="flex flex-wrap items-center space-x-2">
            <div className="flex-1">
              <button
                type="submit"
                className="w-full md:w-52 justify-center primary-button"
                disabled={isSubmitting}
              >
                Iniciar sesi&oacute;n
              </button>
            </div>
            <div className="w-full mb-4 mt-2 md:mt-0 md:mb-0 md:w-auto text-right">
              <Link
                to="/login/instalink"
                className="text-xs text-gray-500 hover:text-blue-600 underline"
              >
                No s&eacute; mi contrase&ntilde;a
              </Link>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
