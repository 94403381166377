import React from 'react';

/**
 * Button component that redirects the user to the /login/google page.
 *
 * @example <GoogleLoginButton />
 */
export default function GoogleLoginButton() {
  const onClick = () => {
    window.location.href = '/login/google';
  };

  return (
    <button
      type="button"
      className="button text-white w-52 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
      style={{ backgroundColor: '#dd4b39' }}
      onClick={onClick}
    >
      Google
    </button>
  );
}
