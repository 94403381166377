import { useField, useFormikContext } from 'formik';

import { useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { useEffect, useState } from 'react';
import { useList } from 'react-use';

interface HasId {
  id: number | string;
}

function useDropdown() {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({ open: isOpen, onOpenChange: setIsOpen });
  const click = useClick(context);
  const dismiss = useDismiss(context, { referencePress: true });
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  return { isOpen, refs, floatingStyles, getReferenceProps, getFloatingProps };
}

export function useMultipleDropdown<T extends HasId>(
  selected: T[],
  onChange: (items: T[]) => void,
) {
  const [selectedItems, { push, removeAt }] = useList(selected);
  const dropdownProps = useDropdown();

  const handleSelect = (item: T) => {
    if (!selectedItems.includes(item)) {
      push(item);
    }
  };

  const handleRemove = (item: T) => {
    const index = selectedItems.findIndex((option) => option.id === item.id);
    removeAt(index);
  };

  useEffect(() => {
    onChange(selectedItems);
  }, [selectedItems]);

  return { ...dropdownProps, selectedItems, handleSelect, handleRemove };
}

export function useCustomField<T>(name: string, autoSubmit = false) {
  const [field] = useField<T>(name);
  const { errors, handleBlur, setFieldValue, submitForm, touched } = useFormikContext();

  const onChange = (item: T) => {
    setFieldValue(name, item, true);
    if (autoSubmit) setTimeout(() => submitForm(), 0);
  };

  return {
    value: field.value,
    errors,
    handleBlur,
    setFieldValue: onChange,
    touched,
  };
}
