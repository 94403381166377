import { alert } from 'notie';

function showMessage(
  message: string,
  type: 'error' | 'success' | 'warning',
  options: { keepOpen?: boolean } = {},
) {
  const { keepOpen } = options;

  alert({
    type,
    text: message,
    stay: keepOpen || false,
    position: 'bottom',
  });
}

export function showSuccessMessage(message: string, options?: { keepOpen?: boolean }) {
  showMessage(message, 'success', options);
}

export function showErrorMessage(message: string, options?: { keepOpen?: boolean }) {
  showMessage(message, 'error', options);
}

export function showWarningMessage(message, options?: { keepOpen?: boolean }) {
  showMessage(message, 'warning', options);
}
