import classNames from 'classnames';

interface Props {
  children: React.ReactNode;
  className?: string;
}

/**
 * Component that renders a Tailwind container.
 *
 * @example <Card>Content of the card</Card>
 */
export default function Card({ children, className }: Props) {
  return (
    <div
      className={classNames('w-full rounded-md shadow-lg bg-white print:shadow-none', className)}
    >
      {children}
    </div>
  );
}
