import GoogleLoginButton from './GoogleLoginButton';
import WindowsLoginButton from './WindowsLoginButton';

/**
 * Partial section that renders buttons to login with social third-party
 * providers.
 *
 * @example <ThirdPartyProvidersSection />
 */
export default function ThirdPartyProvidersSection() {
  return (
    <div className="space-y-4">
      <p className="text-sm text-center opacity-60">
        Inicie sesión con uno de los siguientes proveedores
      </p>
      <div className="text-center space-y-4">
        <GoogleLoginButton />
        <WindowsLoginButton />
      </div>
    </div>
  );
}
