import axios from 'axios';
import * as Cookies from 'js-cookie';
import { stringifySearchParams } from './searchParams';

const api = axios.create({ paramsSerializer: stringifySearchParams });
export const xsrfToken = Cookies.get('xsrf_token');

/**
 * Performs an ajax request using axios.
 *
 * @param {string} method - the request method to be used when making the request
 * @param {string} url - the server URL that will be used for the request
 * @param {Object} data - the data to be sent as the request body
 * @param {Object} params - the URL parameters to be sent with the request
 */
function sendAPIRequest<T>(
  method: 'post' | 'get' | 'delete' | 'put',
  url: string,
  data: Record<string, unknown> = {},
  params?: Record<string, unknown>,
): Promise<T> {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  if (xsrfToken) headers['x-csrf-token'] = xsrfToken;

  return api({
    method,
    url,
    data,
    params,
    withCredentials: true,
    headers,
  }).then((response) => response.data);
}

/**
 * Performs a GET request.
 *
 * @param {string} url - the server URL that will be used for the request
 * @param {Object} params - the URL parameters to be sent with the request
 */
export function fetchAPI<T>(url: string, params?: Record<string, unknown>) {
  return sendAPIRequest<T>('get', `/api${url}`, undefined, params);
}

/**
 * Performs a POST request.
 *
 * @param url - the server URL that will be used for the request
 * @param data - the data to be sent as the request body
 * @param params - the URL parameters to be sent with the request
 */
export function postAPI<T>(
  url: string,
  data: Record<string, unknown>,
  params?: Record<string, unknown>,
) {
  return sendAPIRequest<T>('post', `/api${url}`, data, params);
}

/**
 * Performs a DELETE request.
 *
 * @param url - the server URL that will be used for the request
 * @param params - the URL parameters to be sent with the request
 */
export function deleteAPI<T>(url: string, params?: Record<string, unknown>) {
  return sendAPIRequest<T>('delete', `/api${url}`, undefined, params);
}

/**
 * Performs a PUT request.
 *
 * @param url - the server URL that will be used for the request
 * @param data - the data to be sent as the request body
 * @param params - the URL parameters to be sent with the request
 */
export function putAPI<T>(
  url: string,
  data: Record<string, unknown>,
  params?: Record<string, unknown>,
) {
  return sendAPIRequest<T>('put', `/api${url}`, data, params);
}
