import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { boolean, object, string } from 'yup';
import ValidationError from '../../errors/ValidationError';
import { isValidPhone } from '../../lib/validation';
import CheckboxField from '../form/CheckboxField';
import { PhoneInputField } from '../form/PhoneInputField';

interface Props {
  onSubmit: (data) => Promise<void>;
}

export default function SignupForm({ onSubmit }: Props) {
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    primaryPhone: null,
    congregation: { name: '' },
    termsAccepted: false,
  };

  const validationSchema = object().shape({
    firstName: string()
      .trim()
      .required('Este campo es obligatorio')
      .min(2, 'Introduzca al menos 2 caracteres')
      .max(30, 'Introduzca menos de 30 caracteres'),
    lastName: string()
      .trim()
      .required('Este campo es obligatorio')
      .min(2, 'Introduzca al menos 2 caracteres')
      .max(30, 'Introduzca menos de 30 caracteres'),
    email: string().email('Dirección de correo inválida').required('Este campo es obligatorio'),
    primaryPhone: string()
      .nullable()
      .test('primary-phone', 'Número de teléfono inválido', isValidPhone),
    congregation: object().shape({
      name: string()
        .trim()
        .required('Este campo es obligatorio')
        .min(3, 'Introduzca al menos 3 caracteres')
        .max(100, 'Introduzca menos de 100 caracteres'),
    }),
    termsAccepted: boolean().isTrue('Debe autorizar la recopilación y procesamiento de sus datos'),
  });

  const handleSubmit = async (data, helpers: FormikHelpers<typeof initialValues>) => {
    try {
      await onSubmit(data);
    } catch (error) {
      if (error instanceof ValidationError) helpers.setErrors(error.formikErrors);
      else throw error;
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form className="space-y-8">
          <ErrorMessage name="termsAccepted" component="div" className="form-error-message" />
          <div className="grid grid-cols-1 gap-6">
            <div className="relative">
              <Field
                id="firstName"
                name="firstName"
                title="Nombre"
                placeholder="Nombre"
                className={classNames('form-input rounded-md', {
                  invalid: !!errors.firstName && touched.firstName,
                })}
              />
              <label htmlFor="firstName">Nombre</label>
              <ErrorMessage name="firstName" component="div" className="form-input-error-message" />
            </div>
            <div className="relative">
              <Field
                id="lastName"
                name="lastName"
                title="Apellido"
                placeholder="Apellido"
                className={classNames('form-input rounded-md', {
                  invalid: !!errors.lastName && touched.lastName,
                })}
              />
              <label htmlFor="lastName">Apellido</label>
              <ErrorMessage name="lastName" component="div" className="form-input-error-message" />
            </div>
            <div className="relative">
              <Field
                id="email"
                name="email"
                type="email"
                placeholder="Correo electrónico"
                className={classNames('form-input rounded-md', {
                  invalid: !!errors.email && touched.email,
                })}
              />
              <label htmlFor="email">Correo electrónico</label>
              <ErrorMessage name="email" component="div" className="form-input-error-message" />
            </div>
            <div className="relative">
              <Field
                id="primaryPhone"
                name="primaryPhone"
                placeholder="Teléfono"
                as={PhoneInputField}
              />
              <ErrorMessage
                name="primaryPhone"
                component="div"
                className="form-input-error-message"
              />
            </div>
            <div className="relative">
              <Field
                id="congregation"
                name="congregation.name"
                placeholder="Congregación"
                className={classNames('form-input rounded-md', {
                  invalid: !!errors.congregation?.name && touched.congregation?.name,
                })}
              />
              <ErrorMessage
                name="congregation.name"
                component="div"
                className="form-input-error-message"
              />
            </div>
            <div className="relative">
              <CheckboxField
                id="termsAccepted"
                name="termsAccepted"
                label="Autorizo que se recopilen y procesen mis datos personales proporcionados en este formulario."
              />
            </div>
          </div>
          <button type="submit" className="primary-button w-full" disabled={isSubmitting}>
            Crear cuenta
          </button>
        </Form>
      )}
    </Formik>
  );
}
